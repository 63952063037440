import IScene from './IScene';
import Bedroom from './Bedroom';
import Ensuite from './Ensuite';
import Kitchen from './Kitchen';
import LivingRoom from './LivingRoom';
import MainBath from './MainBath';
import MasterBedroom from './MasterBedroom';

const Scenes: Array<IScene> = [
	Kitchen,
	LivingRoom,
	MainBath,
	Bedroom,
	MasterBedroom,
	Ensuite,
];

export default Scenes;
