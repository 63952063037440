import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const MainBath: IScene = {
	name: 'Main Bath',
	hotspot: {
		x: 55,
		y: 340,
	},
	images: {
		scheme1: Scheme1.MainBath,
		scheme2: Scheme2.MainBath,
	},
	default: {
		latitude: -0.2039889714013161,
		longitude: 1.5578513450770217,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.1321692006880799,
				longitude: 0.08689903632816116,
			},
			target: {
				name: 'Living Room',
				view: {
					latitude: -0.0831598055361984,
					longitude: 0.043076046130210315,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.5022653365376422,
				longitude: 0.1354356185421534,
			},
			target: {
				name: 'Kitchen',
				view: {
					latitude: -0.052735486437590406,
					longitude: 0.14837304778183102,
				},
			},
		},
	],
};

export default MainBath;
