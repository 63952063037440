import SCSS from '../SVG.module.scss';

const index = () => {
	return (
		<g className={SCSS.Flooring}>
			<line x1='38.14' y1='264.1' x2='15.64' y2='264.1' />
			<line x1='212.59' y1='215.94' x2='238.13' y2='215.97' />
			<line x1='66.98' y1='303.78' x2='41.44' y2='303.78' />
			<line x1='257.25' y1='278.03' x2='257.25' y2='227.84' />
			<line x1='257.25' y1='150.25' x2='257.25' y2='101.53' />
			<polyline points='135.69,49.43 133.24,49.43 133.24,44.89 61.16,39.85' />
		</g>
	);
};

export default index;
