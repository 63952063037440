import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const Bedroom: IScene = {
	name: 'Bedroom',
	hotspot: {
		x: 175,
		y: 135,
	},
	images: {
		scheme1: Scheme1.Bedroom,
		scheme2: Scheme2.Bedroom,
	},
	default: {
		latitude: -0.08162101370227193,
		longitude: 1.566822517466613,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.13752634874961922,
				longitude: 3.8677513868174547,
			},
			target: {
				name: 'Kitchen',
				view: {
					latitude: -0.09825264799377198,
					longitude: 3.811397854340392,
				},
			},
		},
	],
};

export default Bedroom;
