import SCSS from '../SVG.module.scss';

const Furniture = () => {
	return (
		<g className={SCSS.Furniture}>
			<rect x='122.64' y='81.98' width='9' height='45' />
			<rect x='126.43' y='86.48' width='1.12' height='36' />
			<polyline points='127.55,101.48 129.24,101.48 129.24,107.48 127.55,107.48' />
			<polyline points='126.43,107.48 124.74,107.48 124.74,101.48 126.43,101.48' />
			<rect x='70.06' y='200.57' width='22.5' height='35.62' />
			<rect x='63.9' y='204.13' width='6.15' height='12' />
			<line x1='64.88' y1='216.13' x2='64.88' y2='204.13' />
			<rect x='63.9' y='220.63' width='6.15' height='12' />
			<line x1='64.88' y1='220.63' x2='64.88' y2='232.63' />
			<rect x='92.56' y='204.13' width='6.15' height='12' />
			<line x1='97.73' y1='216.13' x2='97.73' y2='204.13' />
			<rect x='92.56' y='220.63' width='6.15' height='12' />
			<line x1='97.73' y1='220.63' x2='97.73' y2='232.63' />
			<rect x='85.08' y='76.01' width='22.5' height='22.5' />
			<rect x='45.84' y='53.61' width='24' height='65.27' />
			<polyline points='67.59,118.88 67.59,115.88 48.85,115.88 48.85,56.61 67.59,56.61 67.59,53.61' />
			<line x1='69.84' y1='76.59' x2='48.85' y2='76.65' />
			<line x1='69.84' y1='95.91' x2='48.85' y2='95.91' />
			<rect x='49.45' y='57.28' width='4.5' height='57.93' />
			<rect x='81.61' y='134.06' width='25.98' height='24' />
			<polyline points='81.61,136.31 84.61,136.31 84.61,155.06 104.58,155.06 104.58,136.31 107.58,136.31' />
			<rect x='85.28' y='149.95' width='18.64' height='4.5' />
			<rect x='194.43' y='163.13' width='45.75' height='0.75' />
			<polyline points='239.81,149.28 239.81,103.88 194.81,103.88 194.81,131.94' />
			<polyline points='194.81,145.96 194.81,163.13 239.81,163.13 239.81,155.3' />
			<rect x='241.1' y='150.59' width='13.29' height='13.29' />
			<rect x='180.23' y='150.59' width='13.29' height='13.29' />
			<polyline points='236.43,154.6 236.43,161.71 218.81,161.71 218.81,150.94' />
			<polyline points='213.57,149.86 198.18,149.86 198.18,161.71 215.43,161.71 215.43,150.24' />
			<polygon points='240.18,155.38 240.18,149.42 194.43,131.8 194.43,145.88' />
			<rect x='187.55' y='301.29' width='45.75' height='0.75' />
			<polyline points='232.93,287.44 232.93,242.04 187.93,242.04 187.93,270.1' />
			<polyline points='187.93,284.12 187.93,301.29 232.93,301.29 232.93,293.46' />
			<rect x='234.22' y='288.75' width='13.29' height='13.29' />
			<rect x='173.35' y='288.75' width='13.29' height='13.29' />
			<polyline points='229.55,292.76 229.55,299.87 211.93,299.87 211.93,289.1' />
			<polyline points='206.69,288.01 191.3,288.01 191.3,299.87 208.55,299.87 208.55,288.4' />
			<polygon points='233.3,293.54 233.3,287.58 187.55,269.96 187.55,284.04' />
		</g>
	);
};

export default Furniture;
