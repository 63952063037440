import SVG from '../SVG.module.scss';

const DoorSwings = () => {
	return (
		<g className={SVG.DoorSwings}>
			<path d='M165.17,163.88c0-13.59-10.64-24.8-24.21-25.51' />
			<path d='M150.92,226.53c13.57-0.71,24.21-11.92,24.21-25.51' />
			<path d='M42.77,331.03c13.57-0.71,24.21-11.92,24.21-25.51' />
			<path d='M238.13,214.22c0-13.59-10.64-24.8-24.21-25.51' />
			<rect x='143.55' y='49.43' width='17.59' height='0.75' />
			<path d='M139.06,76.01c14.39,0,26.16-11.45,26.56-25.84' />
			<path d='M37.37,271.69c0.51-1.9,0.77-3.86,0.77-5.82' />
			<path d='M11.56,297.91c6.71,0,13.18-2.5,18.15-7' />
		</g>
	);
};

export default DoorSwings;
