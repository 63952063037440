import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const Ensuite: IScene = {
	name: 'Ensuite',
	hotspot: {
		x: 225,
		y: 188,
	},
	images: {
		scheme1: Scheme1.Ensuite,
		scheme2: Scheme2.Ensuite,
	},
	default: {
		latitude: -0.17285369590290234,
		longitude: 0.003290702536821684,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.3796139268888381,
				longitude: 3.2871486385711566,
			},
			target: {
				name: 'Master Bedroom',
				view: {
					latitude: -0.05148348956006599,
					longitude: 3.1436092205498065,
				},
			},
		},
	],
};

export default Ensuite;
