import IImage from './IImage';
import Scheme_1_Bedroom from './The_Grand_Unit_B-Scheme_01-Bedroom.jpg';
import Scheme_1_Ensuite from './The_Grand_Unit_B-Scheme_01-Ensuite.jpg';
import Scheme_1_Kitchen from './The_Grand_Unit_B-Scheme_01-Kitchen.jpg';
import Scheme_1_LivingRoom from './The_Grand_Unit_B-Scheme_01-Living-Room.jpg';
import Scheme_1_MainBath from './The_Grand_Unit_B-Scheme_01-Main-Bath.jpg';
import Scheme_1_Master_Bedroom from './The_Grand_Unit_B-Scheme_01-Master-Bedroom.jpg';

import Scheme_2_Bedroom from './The_Grand_Unit_B-Scheme_02-Bedroom.jpg';
import Scheme_2_Ensuite from './The_Grand_Unit_B-Scheme_02-Ensuite.jpg';
import Scheme_2_Kitchen from './The_Grand_Unit_B-Scheme_02-Kitchen.jpg';
import Scheme_2_LivingRoom from './The_Grand_Unit_B-Scheme_02-Living-Room.jpg';
import Scheme_2_MainBath from './The_Grand_Unit_B-Scheme_02-Main-Bath.jpg';
import Scheme_2_Master_Bedroom from './The_Grand_Unit_B-Scheme_02-Master-Bedroom.jpg';

export const Scheme1: IImage = {
	Bedroom: Scheme_1_Bedroom,
	Ensuite: Scheme_1_Ensuite,
	Kitchen: Scheme_1_Kitchen,
	LivingRoom: Scheme_1_LivingRoom,
	MainBath: Scheme_1_MainBath,
	MasterBedroom: Scheme_1_Master_Bedroom,
};

export const Scheme2: IImage = {
	Bedroom: Scheme_2_Bedroom,
	Ensuite: Scheme_2_Ensuite,
	Kitchen: Scheme_2_Kitchen,
	LivingRoom: Scheme_2_LivingRoom,
	MainBath: Scheme_2_MainBath,
	MasterBedroom: Scheme_2_Master_Bedroom,
};
