import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const LivingRoom: IScene = {
	name: 'Living Room',
	hotspot: {
		x: 75,
		y: 125,
	},
	images: {
		scheme1: Scheme1.LivingRoom,
		scheme2: Scheme2.LivingRoom,
	},
	default: {
		latitude: -0.047820548587573786,
		longitude: 0.5981670645111755,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.138944795628829,
				longitude: 3.1404975694337103,
			},
			target: {
				name: 'Kitchen',
				view: {
					latitude: -0.07686116513934316,
					longitude: 3.0765686498965428,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.13700718594338301,
				longitude: 3.2664970088514274,
			},
			target: {
				name: 'Main Bath',
				view: {
					latitude: -0.035375721856647946,
					longitude: 3.13175357095263,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.3466316049031086,
				longitude: 2.3775261721118586,
			},
			target: {
				name: 'Master Bedroom',
				view: {
					latitude: -0.09609589019213827,
					longitude: 2.4150615428783797,
				},
			},
		},
	],
};

export default LivingRoom;
