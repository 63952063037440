import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const Kitchen: IScene = {
	name: 'Kitchen',
	hotspot: {
		x: 80,
		y: 270,
	},
	images: {
		scheme1: Scheme1.Kitchen,
		scheme2: Scheme2.Kitchen,
	},
	default: {
		latitude: 0.10652241922946293,
		longitude: 6.020508405192444,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.3126515589747827,
				longitude: 6.254283265837523,
			},
			target: {
				name: 'Living Room',
				view: {
					latitude: -0.008113151759628678,
					longitude: 0.07019800110108301,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.72359882565424,
				longitude: 3.9286918422960175,
			},
			target: {
				name: 'Main Bath',
				view: {
					latitude: -0.06298358095080347,
					longitude: 4.12493604612336,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.09064906731351874,
				longitude: 0.5854066730016722,
			},
			target: {
				name: 'Bedroom',
				view: {
					latitude: -0.07504665377656927,
					longitude: 0.7418652389091734,
				},
			},
		},
	],
};

export default Kitchen;
