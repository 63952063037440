import SCSS from '../SVG.module.scss';

const Backdrop = () => {
	return (
		<g id='Backdrop' className={SCSS.Backdrop}>
			<polyline
				className={SCSS.Balcony}
				id='Balcony'
				points='263.19,8.95 138.94,0.27 138.94,76.01 263.08,76.01 263.08,75.42'
			/>
			<polyline
				className={SCSS.Interiors}
				id='Interior'
				points='139.06,39.46 42.61,32.72 42.61,232.96 7.43,232.96 7.43,324.42 34.59,324.42 
		34.59,392.23 86.61,392.23 86.61,305.29 263.08,305.44 263.08,76.01 138.94,76.01 139.06,39.46'
			/>
		</g>
	);
};

export default Backdrop;
