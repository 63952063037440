import SCSS from '../SVG.module.scss';

export const Walls = () => {
	return (
		<g className={SCSS.Walls}>
			<polyline
				points='66.98,302.04 141.45,302.04 141.45,197.54 149.59,197.54 149.59,201.03 146.71,201.03 
			146.71,238.67 166.2,239.06 166.2,246.17 162.71,246.17 162.71,242.16 146.68,242.16 146.68,302.04 162.71,302.04 162.71,300.21 
			166.2,300.21 166.2,302.04 257.25,302.04 257.25,278.03 259.7,277.94 259.7,305.29 86.61,305.29 86.61,316.23 86.61,316.23 
			86.61,317.78 86.61,389 86.61,392.23 34.59,392.23 34.59,324.42 7.43,324.42 7.43,297.91 11.56,297.91 11.56,302.04 14.04,302.04 
			14.04,305.52 11.56,305.52 11.56,320.67 34.86,320.67 34.86,305.52 32.05,305.52 32.05,302.04 41.44,302.04 41.44,305.52 
			38.37,305.52 38.37,389 83.37,389 83.37,319.46 70.11,319.46 70.11,305.52 66.98,305.52 66.98,302.04'
			/>
			<polygon
				points='7.43,232.96 7.43,270.89 11.56,270.89 11.56,265.87 15.64,265.87 15.64,262.36 12.68,262.36 
			12.68,236.19 40.59,236.19 40.59,262.36 38.14,262.36 38.14,265.84 45.84,265.84 45.84,38.78 61.16,39.85 61.33,37.41 42.61,36.1 
			42.61,232.96'
			/>
			<polygon
				points='154.73,81.83 154.73,83.58 158.22,83.58 158.22,81.83 257.25,81.83 257.25,101.53 259.7,101.53 
			259.7,79.38 135.69,79.38 135.69,76.01 133.24,76.01 133.24,167.37 139.63,167.37 139.63,163.88 136.72,163.88 136.72,133.85 
			158.22,133.85 158.22,128.61 154.73,128.61 154.73,130.37 136.72,130.37 136.72,81.83'
			/>
			<polygon
				points='190.25,214.23 190.25,216.44 202.06,216.44 202.06,214.23 212.59,214.23 212.59,217.71 
			181.72,217.71 181.72,201.03 175.13,201.03 175.13,197.54 181.72,197.54 181.72,167.37 165.17,167.37 165.17,163.88 
			257.25,163.88 257.25,150.25 259.7,150.25 259.7,227.84 257.25,227.84 257.25,217.71 238.13,217.71 238.13,214.23 257.2,214.23 
			257.2,169.14 185.2,169.14 185.2,214.23'
			/>
			<circle cx='249.79' cy='60.36' r='11.22' />
		</g>
	);
};
export default Walls;
