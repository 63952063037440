import SCSS from '../SVG.module.scss';

const Lineworks = () => {
	return (
		<g className={SCSS.Lineworks}>
			<rect x='139.63' y='138.37' width='1.33' height='25.51' />
			<polygon points='11.56,270.89 10.62,271.83 29.71,290.92 30.68,290.01' />
			<rect
				x='26.01'
				y='256.89'
				transform='matrix(0.2588 -0.9659 0.9659 0.2588 -239.2334 224.5064)'
				width='1.33'
				height='22.5'
			/>
			<rect x='17.42' y='302.04' width='9.76' height='1.48' />
			<rect x='22.3' y='303.52' width='9.76' height='1.48' />
			<rect x='163.24' y='272.44' width='1.48' height='27.77' />
			<rect x='164.72' y='258.56' width='1.48' height='27.77' />
			<rect x='149.59' y='201.03' width='1.33' height='25.51' />
			<rect x='41.44' y='305.52' width='1.33' height='25.51' />
			<rect x='212.59' y='188.72' width='1.33' height='25.51' />
			<rect x='156.72' y='95.22' width='1.5' height='23.26' />
			<rect x='155.22' y='83.58' width='1.5' height='23.26' />
			<rect x='139.81' y='76.01' width='30.56' height='3.38' />
			<rect x='170.37' y='76.01' width='1.5' height='3.38' />
			<rect x='171.87' y='76.01' width='28.03' height='3.38' />
			<rect x='199.9' y='76.01' width='1.5' height='3.38' />
			<rect x='201.4' y='76.01' width='28.03' height='3.38' />
			<rect x='229.42' y='76.01' width='1.5' height='3.38' />
			<rect x='230.92' y='76.01' width='28.03' height='3.38' />
			<rect x='259.7' y='80.13' width='3.38' height='20.65' />
			<rect x='259.7' y='100.78' width='3.38' height='1.5' />
			<rect x='259.7' y='102.28' width='3.38' height='25.07' />
			<rect x='259.7' y='127.35' width='3.38' height='1.5' />
			<rect x='259.7' y='128.85' width='3.38' height='20.65' />
			<line x1='261.39' y1='102.28' x2='261.39' y2='127.35' />
			<line x1='261.39' y1='128.85' x2='261.39' y2='149.5' />
			<rect x='259.7' y='149.5' width='3.38' height='1.5' />
			<rect x='259.7' y='151' width='3.38' height='25.07' />
			<rect x='259.7' y='176.07' width='3.38' height='1.5' />
			<rect x='259.7' y='177.57' width='3.38' height='25.07' />
			<rect x='259.7' y='202.65' width='3.38' height='1.5' />
			<rect x='259.7' y='204.15' width='3.38' height='25.07' />
			<rect x='259.7' y='229.22' width='3.38' height='1.5' />
			<rect x='259.7' y='230.72' width='3.38' height='25.07' />
			<rect x='259.7' y='255.8' width='3.38' height='1.5' />
			<rect x='259.7' y='257.3' width='3.38' height='20.65' />
			<rect x='259.7' y='304.69' width='3.38' height='0.75' />
			<rect x='259.7' y='279.44' width='3.38' height='25.25' />
			<rect x='259.7' y='277.94' width='3.38' height='1.5' />
			<line x1='261.39' y1='277.94' x2='261.39' y2='257.3' />
			<line x1='261.39' y1='255.8' x2='261.39' y2='230.72' />
			<rect x='135.69' y='76.01' width='4.12' height='3.38' />
			<rect x='135.69' y='48.68' width='3.38' height='0.75' />
			<rect x='135.69' y='43.36' width='3.38' height='5.32' />
			<line x1='137.37' y1='48.68' x2='137.37' y2='43.36' />
			<rect
				x='108.36'
				y='38.37'
				transform='matrix(0.0698 -0.9976 0.9976 0.0698 63.3459 146.1779)'
				width='3.38'
				height='1.5'
			/>
			<rect
				x='121.24'
				y='27.87'
				transform='matrix(0.0698 -0.9976 0.9976 0.0698 74.4257 159.8604)'
				width='3.38'
				height='24.32'
			/>
			<rect
				x='81.85'
				y='36.52'
				transform='matrix(0.0698 -0.9976 0.9976 0.0698 40.5343 118.0079)'
				width='3.38'
				height='1.5'
			/>
			<rect
				x='95.11'
				y='25.66'
				transform='matrix(0.0698 -0.9976 0.9976 0.0698 51.9401 132.0929)'
				width='3.38'
				height='25.07'
			/>
			<line x1='135.06' y1='40.87' x2='110.8' y2='39.18' />
			<line x1='109.3' y1='39.07' x2='84.29' y2='37.32' />
			<rect
				x='59.76'
				y='34.98'
				transform='matrix(0.0698 -0.9976 0.9976 0.0698 21.5247 94.533)'
				width='3.38'
				height='1.5'
			/>
			<polygon points='60.82,33.99 42.61,32.72 42.61,36.1 60.58,37.36' />
			<rect x='161.14' y='49.43' width='4.5' height='0.75' />
			<rect x='139.06' y='49.43' width='4.49' height='0.75' />

			<rect
				x='70.81'
				y='26.17'
				transform='matrix(0.0698 -0.9976 0.9976 0.0698 31.0295 106.2705)'
				width='3.38'
				height='20.65'
			/>
			<line x1='82.79' y1='37.22' x2='62.2' y2='35.78' />
			<line x1='241.43' y1='211.77' x2='253.43' y2='211.77' />
			<line x1='250.67' y1='203.29' x2='250.67' y2='204.05' />
			<path d='M251.79,204.05c0,0.1,0.08,0.19,0.19,0.19' />
			<line x1='251.79' y1='203.29' x2='251.79' y2='204.05' />
			<path d='M251.98,203.1c-0.1,0-0.19,0.08-0.19,0.19' />
			<line x1='250.86' y1='204.24' x2='255.08' y2='204.24' />
			<path d='M250.67,204.05c0,0.1,0.08,0.19,0.19,0.19' />
			<line x1='250.67' y1='203.29' x2='250.67' y2='204.05' />
			<path d='M250.86,203.1c-0.1,0-0.19,0.08-0.19,0.19' />
			<line x1='255.08' y1='203.1' x2='250.86' y2='203.1' />
			<path d='M255.08,204.24c0.08,0,0.14-0.06,0.14-0.14' />
			<line x1='255.22' y1='203.24' x2='255.22' y2='204.1' />
			<path d='M255.22,203.24c0-0.08-0.06-0.14-0.14-0.14' />
			<line x1='253.43' y1='195.57' x2='253.43' y2='203.1' />
			<line x1='241.43' y1='195.57' x2='253.43' y2='195.57' />
			<line x1='241.43' y1='211.77' x2='241.43' y2='195.57' />
			<line x1='253.43' y1='204.24' x2='253.43' y2='211.77' />
			<polyline points='251.41,169.14 251.41,193.14 240.33,193.14 240.33,214.17' />
			<path
				d='M242.7,187.32c-0.67,0-1.06-0.07-1.48-0.14c-0.54-0.09-1.12-0.2-1.65-0.4c-0.55-0.21-1.04-0.53-1.43-0.86
			c-0.3-0.25-0.55-0.5-0.73-0.73c-0.15-0.19-0.24-0.36-0.35-0.6c-0.1-0.23-0.2-0.51-0.28-0.8c-0.08-0.31-0.14-0.62-0.17-0.91
			c-0.03-0.3-0.03-0.57-0.03-0.91c0-0.32,0-0.7,0.03-1.04c0.03-0.32,0.08-0.61,0.12-0.87c0.04-0.22,0.08-0.43,0.15-0.63
			c0.08-0.21,0.19-0.41,0.32-0.61c0.18-0.29,0.38-0.58,0.71-0.89c0.35-0.33,0.84-0.69,1.26-0.92c0.41-0.22,0.76-0.32,1.1-0.41
			c0.29-0.08,0.56-0.15,1.04-0.23c0.39-0.07,0.92-0.13,1.4-0.13'
			/>
			<line x1='242.7' y1='176.26' x2='251.41' y2='176.26' />
			<line x1='242.7' y1='187.32' x2='251.41' y2='187.32' />
			<rect x='16.42' y='238.7' width='20.44' height='22.69' />
			<rect x='38.37' y='364.97' width='45' height='24' />
			<path
				d='M61.95,386.59l17.19-2.07c1.13-0.14,1.98-1.1,1.98-2.23v-10.63c0-1.14-0.85-2.1-1.98-2.23l-17.19-2.07
			c-0.71-0.09-1.44-0.09-2.15,0l-17.19,2.07c-1.13,0.14-1.98,1.1-1.98,2.23v10.63c0,1.14,0.85,2.1,1.98,2.23l17.19,2.07
			C60.51,386.68,61.23,386.68,61.95,386.59z'
			/>
			<line x1='67.6' y1='320.84' x2='79.6' y2='320.84' />
			<line x1='76.84' y1='329.32' x2='76.84' y2='328.55' />
			<path d='M78.14,328.36c-0.1,0-0.19,0.08-0.19,0.19' />
			<line x1='77.96' y1='329.32' x2='77.96' y2='328.55' />
			<path d='M77.96,329.32c0,0.1,0.08,0.19,0.19,0.19' />
			<line x1='77.03' y1='328.36' x2='81.25' y2='328.36' />
			<path d='M77.03,328.36c-0.1,0-0.19,0.08-0.19,0.19' />
			<line x1='76.84' y1='329.32' x2='76.84' y2='328.55' />
			<path d='M76.84,329.32c0,0.1,0.08,0.19,0.19,0.19' />
			<line x1='81.25' y1='329.5' x2='77.03' y2='329.5' />
			<path d='M81.39,328.51c0-0.08-0.06-0.14-0.14-0.14' />
			<line x1='81.39' y1='329.36' x2='81.39' y2='328.51' />
			<path d='M81.25,329.5c0.08,0,0.14-0.06,0.14-0.14' />
			<line x1='79.6' y1='337.03' x2='79.6' y2='329.5' />
			<line x1='67.6' y1='337.03' x2='79.6' y2='337.03' />
			<line x1='67.6' y1='320.84' x2='67.6' y2='337.03' />
			<line x1='79.6' y1='328.36' x2='79.6' y2='320.84' />
			<polyline points='77.58,364.97 77.58,340.97 66.5,340.97 66.5,319.46 70.11,319.46' />
			<path
				d='M68.87,346.79c-0.67,0-1.06,0.07-1.48,0.14c-0.54,0.09-1.12,0.2-1.65,0.4c-0.55,0.21-1.04,0.53-1.43,0.86
			c-0.3,0.25-0.55,0.5-0.73,0.73c-0.15,0.19-0.24,0.36-0.35,0.6c-0.1,0.23-0.2,0.51-0.28,0.8c-0.08,0.31-0.14,0.62-0.17,0.91
			c-0.03,0.3-0.03,0.57-0.03,0.91c0,0.32,0,0.7,0.03,1.04c0.03,0.32,0.08,0.61,0.12,0.87c0.04,0.22,0.08,0.43,0.15,0.63
			c0.08,0.21,0.19,0.41,0.32,0.61c0.18,0.29,0.38,0.58,0.71,0.89c0.35,0.33,0.84,0.69,1.26,0.92c0.41,0.22,0.76,0.32,1.1,0.41
			c0.29,0.08,0.56,0.15,1.04,0.23c0.39,0.07,0.92,0.13,1.4,0.13'
			/>
			<line x1='68.87' y1='357.85' x2='77.58' y2='357.85' />
			<line x1='68.87' y1='346.79' x2='77.58' y2='346.79' />
			<polygon points='263.08,76.01 263.08,80.13 259.7,80.13 259.7,79.38 258.95,79.38 258.95,76.01' />
			<polygon
				points='138.94,0.27 263.19,8.95 263.19,75.42 262.07,75.42 262.07,10 140.07,1.47 140.07,38.79 
			138.94,38.79'
			/>
			<rect x='70.11' y='284.04' width='0.72' height='18' />
			<rect x='91.86' y='284.04' width='0.75' height='18' />
			<polyline points='141.42,197.52 123.42,197.52 123.42,284.04 92.61,284.04' />
			<line x1='91.86' y1='284.04' x2='70.83' y2='284.04' />

			<rect
				x='198.8'
				y='201.01'
				transform='matrix(0.2588 -0.9659 0.9659 0.2588 -39.2817 351.427)'
				width='21.11'
				height='0.59'
			/>
			<rect x='206.32' y='211.58' width='0.59' height='2.64' />
			<rect x='206.32' y='169.14' width='0.59' height='21.33' />
			<line x1='190.25' y1='214.23' x2='202.06' y2='214.23' />
			<line x1='138.94' y1='38.79' x2='138.94' y2='39.45' />
			<polygon points='135.17,39.19 139.06,39.46 139.06,43.36 135.69,43.36 135.69,42.61 134.94,42.56' />
			<rect x='123.05' y='198.27' width='0.38' height='18' />
			<path d='M123.05,199.28c-1.95,5.15-1.95,10.84,0,15.99' />
			<line x1='145.73' y1='81.83' x2='145.73' y2='130.37' />
			<line x1='155.68' y1='242.16' x2='155.68' y2='302.04' />
			<line x1='11.56' y1='311.67' x2='34.86' y2='311.67' />
			<rect x='124.17' y='217.01' width='15.75' height='21' />
			<path d='M138.79,227.93c0.23,0,0.42-0.19,0.42-0.42c0-0.23-0.19-0.42-0.42-0.42' />
			<path
				d='M138.29,227.93c0.23,0.28,0.65,0.32,0.93,0.09c0.28-0.23,0.32-0.65,0.09-0.93c-0.23-0.28-0.65-0.32-0.93-0.09
			c-0.03,0.03-0.06,0.06-0.09,0.09'
			/>
			<polyline points='138.79,227.93 138.79,227.93 133.42,227.93' />
			<line x1='133.42' y1='227.09' x2='138.79' y2='227.09' />
			<polyline points='139.45,229.56 138.13,229.56 138.13,227.93' />
			<line x1='139.45' y1='229.56' x2='139.45' y2='227.51' />
			<path d='M133.42,227.09c-0.23,0-0.42,0.19-0.42,0.42c0,0.23,0.19,0.42,0.42,0.42' />
			<rect x='123.05' y='257.65' width='0.38' height='18' />
			<path d='M123.05,258.66c-1.95,5.15-1.95,10.84,0,15.99' />
			<line x1='124.78' y1='255.4' x2='140.29' y2='255.4' />
			<line x1='124.78' y1='277.9' x2='140.29' y2='277.9' />
			<circle cx='126.08' cy='259.16' r='0.68' />
			<circle cx='126.08' cy='264.14' r='0.68' />
			<circle cx='126.08' cy='274.14' r='0.68' />
			<circle cx='126.08' cy='269.16' r='0.68' />
			<line x1='140.53' y1='277.67' x2='140.53' y2='255.64' />
			<line x1='124.55' y1='255.64' x2='124.55' y2='277.67' />
			<path d='M124.78,255.4c-0.13,0-0.23,0.1-0.23,0.23' />
			<path d='M124.55,277.67c0,0.13,0.1,0.23,0.23,0.23' />
			<path d='M140.29,277.9c0.13,0,0.23-0.1,0.23-0.23' />
			<path d='M140.53,255.64c0-0.13-0.1-0.23-0.23-0.23' />
		</g>
	);
};

export default Lineworks;
