import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const MasterBedroom: IScene = {
	name: 'Master Bedroom',
	hotspot: {
		x: 210,
		y: 260,
	},
	images: {
		scheme1: Scheme1.MasterBedroom,
		scheme2: Scheme2.MasterBedroom,
	},
	default: {
		latitude: -0.12347841653817815,
		longitude: 2.3034118602358036,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.6623185772792604,
				longitude: 0.44061354078631654,
			},
			target: {
				name: 'Ensuite',
				view: {
					latitude: -0.012169727639443018,
					longitude: 0.003190818231867409,
				},
			},
		},
	],
};

export default MasterBedroom;
