import SCSS from '../SVG.module.scss';

const Dashed = () => {
	return (
		<g className={SCSS.Dashed}>
			<line x1='123.42' y1='216.27' x2='124.92' y2='216.27' />
			<line x1='127.3' y1='216.27' x2='137.99' y2='216.27' />
			<polyline points='139.17,216.27 140.67,216.27 140.67,214.77' />
			<line x1='140.67' y1='213.01' x2='140.67' y2='200.66' />
			<polyline points='140.67,199.77 140.67,198.27 139.17,198.27' />
			<line x1='136.8' y1='198.27' x2='126.11' y2='198.27' />
			<line x1='124.92' y1='198.27' x2='123.42' y2='198.27' />
		</g>
	);
};

export default Dashed;
