import SCSS from '../SVG.module.scss';

const ThinLineworks = () => {
	return (
		<g className={SCSS.ThinLineworks}>
			<rect x='7.43' y='270.89' width='4.13' height='27.02' />
			<line x1='135.69' y1='76.01' x2='135.69' y2='49.43' />
			<line x1='139.06' y1='76.01' x2='139.06' y2='49.43' />
			<line x1='242.07' y1='211.21' x2='252.87' y2='211.21' />
			<path
				d='M251.13,203.1c-0.31-0.34-0.84-0.36-1.18-0.04c-0.34,0.31-0.36,0.84-0.04,1.18c0.31,0.34,0.84,0.36,1.18,0.04
			c0.01-0.01,0.03-0.03,0.04-0.04'
			/>
			<path d='M253.9,204.24c0,0.1,0.09,0.18,0.19,0.18' />
			<line x1='255.22' y1='204.42' x2='254.09' y2='204.42' />
			<path d='M255.22,204.42c0.1,0,0.19-0.08,0.19-0.19' />
			<line x1='255.4' y1='203.81' x2='255.4' y2='204.23' />
			<line x1='255.4' y1='203.11' x2='255.4' y2='203.53' />
			<path d='M255.4,203.11c0-0.1-0.08-0.19-0.19-0.19' />
			<line x1='254.09' y1='202.92' x2='255.22' y2='202.92' />
			<path d='M254.09,202.92c-0.1,0-0.18,0.08-0.19,0.18' />
			<line x1='255.78' y1='203.53' x2='255.78' y2='203.81' />
			<line x1='255.22' y1='203.53' x2='255.78' y2='203.53' />
			<line x1='255.78' y1='203.81' x2='255.22' y2='203.81' />
			<line x1='252.87' y1='196.14' x2='252.87' y2='203.1' />
			<line x1='242.07' y1='196.14' x2='252.87' y2='196.14' />
			<line x1='242.07' y1='211.21' x2='242.07' y2='196.14' />
			<line x1='252.87' y1='204.24' x2='252.87' y2='211.21' />
			<path
				d='M248.79,186.95c0.41-0.03,0.57-0.07,0.71-0.11c0.13-0.04,0.24-0.08,0.35-0.14c0.11-0.06,0.23-0.12,0.32-0.19
			c0.1-0.07,0.18-0.13,0.25-0.2c0.08-0.08,0.15-0.16,0.22-0.26c0.08-0.12,0.17-0.26,0.24-0.4c0.08-0.16,0.14-0.32,0.19-0.47
			c0.05-0.16,0.09-0.31,0.12-0.47c0.04-0.18,0.07-0.38,0.09-0.57c0.03-0.24,0.05-0.47,0.06-0.71c0.02-0.31,0.03-0.62,0.03-0.97
			c0.01-0.43,0.01-0.91-0.01-1.41c-0.01-0.47-0.04-0.95-0.08-1.34c-0.03-0.32-0.07-0.56-0.14-0.85c-0.05-0.19-0.11-0.4-0.18-0.57
			c-0.05-0.15-0.11-0.27-0.17-0.39c-0.06-0.11-0.13-0.21-0.23-0.33c-0.1-0.12-0.23-0.25-0.36-0.35c-0.12-0.1-0.24-0.18-0.35-0.24
			c-0.09-0.06-0.18-0.11-0.28-0.15c-0.09-0.04-0.19-0.07-0.3-0.09c-0.1-0.02-0.19-0.04-0.31-0.06c-0.12-0.02-0.27-0.04-0.65-0.04'
			/>
			<path
				d='M242.7,186.95c-0.3,0-0.53-0.01-0.74-0.04c-0.1-0.01-0.2-0.02-0.3-0.04s-0.19-0.03-0.28-0.05
			c-0.1-0.02-0.19-0.03-0.29-0.05c-0.1-0.02-0.2-0.04-0.29-0.05c-0.1-0.02-0.2-0.04-0.29-0.06c-0.1-0.02-0.19-0.04-0.29-0.07
			s-0.19-0.05-0.28-0.08c-0.09-0.03-0.18-0.06-0.27-0.09s-0.17-0.07-0.25-0.11c-0.08-0.04-0.16-0.08-0.24-0.12
			c-0.16-0.09-0.31-0.18-0.45-0.28c-0.14-0.1-0.28-0.21-0.4-0.31c-0.13-0.11-0.24-0.21-0.34-0.31c-0.1-0.1-0.19-0.2-0.26-0.29
			c-0.04-0.05-0.07-0.09-0.1-0.13c-0.03-0.04-0.06-0.09-0.08-0.13c-0.03-0.05-0.05-0.09-0.08-0.15c-0.03-0.05-0.05-0.11-0.08-0.18
			c-0.03-0.07-0.06-0.14-0.08-0.21s-0.05-0.15-0.08-0.23s-0.05-0.16-0.07-0.24c-0.02-0.08-0.04-0.16-0.06-0.24
			c-0.04-0.16-0.06-0.32-0.08-0.47c-0.01-0.08-0.02-0.15-0.02-0.22s-0.01-0.14-0.01-0.22c-0.01-0.14-0.01-0.29-0.01-0.46
			c0-0.17,0-0.35,0-0.54c0-0.19,0.01-0.38,0.02-0.55c0.01-0.09,0.01-0.17,0.02-0.25c0.01-0.08,0.02-0.16,0.03-0.24
			s0.02-0.15,0.04-0.23c0.01-0.04,0.01-0.07,0.02-0.11c0.01-0.04,0.01-0.07,0.02-0.11c0.01-0.07,0.03-0.14,0.04-0.2
			s0.03-0.12,0.04-0.18c0.01-0.03,0.02-0.05,0.02-0.08s0.02-0.05,0.03-0.08c0.02-0.05,0.04-0.1,0.07-0.16
			c0.03-0.05,0.06-0.11,0.09-0.17c0.03-0.06,0.07-0.12,0.11-0.17c0.04-0.06,0.07-0.12,0.11-0.18c0.04-0.06,0.08-0.12,0.12-0.17
			c0.04-0.06,0.09-0.11,0.14-0.17c0.05-0.06,0.1-0.12,0.16-0.18c0.06-0.06,0.12-0.12,0.19-0.18c0.07-0.06,0.14-0.12,0.22-0.19
			c0.15-0.12,0.32-0.25,0.49-0.36c0.08-0.06,0.17-0.11,0.25-0.16c0.08-0.05,0.16-0.09,0.23-0.13c0.08-0.04,0.15-0.07,0.22-0.1
			c0.07-0.03,0.14-0.06,0.21-0.08c0.07-0.02,0.14-0.05,0.2-0.07c0.07-0.02,0.14-0.04,0.2-0.06s0.14-0.04,0.21-0.06
			c0.07-0.02,0.14-0.04,0.21-0.05c0.07-0.02,0.15-0.04,0.23-0.05c0.08-0.02,0.17-0.04,0.27-0.05c0.05-0.01,0.1-0.02,0.16-0.03
			c0.05-0.01,0.11-0.02,0.17-0.03c0.12-0.02,0.25-0.04,0.39-0.05c0.14-0.02,0.28-0.03,0.42-0.04s0.28-0.02,0.41-0.02'
			/>
			<line x1='242.7' y1='186.95' x2='248.79' y2='186.95' />
			<line x1='242.7' y1='176.63' x2='248.32' y2='176.63' />
			<line x1='16.42' y1='258.87' x2='36.85' y2='258.87' />
			<circle cx='49.52' cy='376.97' r='0.75' />
			<line x1='68.24' y1='321.4' x2='79.04' y2='321.4' />
			<path
				d='M77.3,328.36c-0.31-0.34-0.84-0.36-1.18-0.04c-0.34,0.31-0.36,0.84-0.04,1.18c0.31,0.34,0.84,0.36,1.18,0.04
			c0.01-0.01,0.03-0.03,0.04-0.04'
			/>
			<path d='M80.26,328.18c-0.1,0-0.18,0.08-0.19,0.18' />
			<line x1='81.39' y1='328.18' x2='80.26' y2='328.18' />
			<path d='M81.57,328.37c0-0.1-0.08-0.19-0.19-0.19' />
			<line x1='81.57' y1='328.79' x2='81.57' y2='328.37' />
			<line x1='81.57' y1='329.5' x2='81.57' y2='329.08' />
			<path d='M81.39,329.68c0.1,0,0.19-0.08,0.19-0.19' />
			<line x1='80.26' y1='329.68' x2='81.39' y2='329.68' />
			<path d='M80.07,329.5c0,0.1,0.09,0.18,0.19,0.18' />
			<line x1='81.95' y1='329.08' x2='81.95' y2='328.79' />
			<line x1='81.39' y1='329.08' x2='81.95' y2='329.08' />
			<line x1='81.95' y1='328.79' x2='81.39' y2='328.79' />
			<line x1='79.04' y1='336.47' x2='79.04' y2='329.5' />
			<line x1='68.24' y1='336.47' x2='79.04' y2='336.47' />
			<line x1='68.24' y1='321.4' x2='68.24' y2='336.47' />
			<line x1='79.04' y1='328.36' x2='79.04' y2='321.4' />
			<path
				d='M74.96,347.16c0.41,0.03,0.57,0.07,0.71,0.11c0.13,0.04,0.24,0.08,0.35,0.14c0.11,0.06,0.23,0.12,0.32,0.19
			c0.1,0.07,0.18,0.13,0.25,0.2c0.08,0.08,0.15,0.16,0.22,0.26c0.08,0.12,0.17,0.26,0.24,0.4c0.08,0.16,0.14,0.32,0.19,0.47
			c0.05,0.16,0.09,0.31,0.12,0.47c0.04,0.18,0.07,0.38,0.09,0.57c0.03,0.24,0.05,0.47,0.06,0.71c0.02,0.31,0.03,0.62,0.03,0.97
			c0.01,0.43,0.01,0.91-0.01,1.41c-0.01,0.47-0.04,0.95-0.08,1.34c-0.03,0.32-0.07,0.56-0.14,0.85c-0.05,0.19-0.11,0.4-0.18,0.57
			c-0.05,0.15-0.11,0.27-0.17,0.39c-0.06,0.11-0.13,0.21-0.23,0.33c-0.1,0.12-0.23,0.25-0.36,0.35c-0.12,0.1-0.24,0.18-0.35,0.24
			c-0.09,0.06-0.18,0.11-0.28,0.15c-0.09,0.04-0.19,0.07-0.3,0.09c-0.1,0.02-0.19,0.04-0.31,0.06c-0.12,0.02-0.27,0.04-0.65,0.04'
			/>
			<path
				d='M68.87,347.16c-0.3,0-0.53,0.01-0.74,0.04c-0.1,0.01-0.2,0.02-0.3,0.04c-0.09,0.01-0.19,0.03-0.28,0.05
			c-0.1,0.02-0.19,0.03-0.29,0.05c-0.1,0.02-0.2,0.04-0.29,0.05s-0.2,0.04-0.29,0.06c-0.1,0.02-0.19,0.04-0.29,0.07
			c-0.09,0.02-0.19,0.05-0.28,0.08c-0.09,0.03-0.18,0.06-0.27,0.09c-0.09,0.03-0.17,0.07-0.25,0.11c-0.08,0.04-0.16,0.08-0.24,0.12
			c-0.16,0.09-0.31,0.18-0.45,0.28c-0.14,0.1-0.28,0.21-0.4,0.31c-0.13,0.11-0.24,0.21-0.34,0.31c-0.1,0.1-0.19,0.2-0.26,0.29
			c-0.04,0.05-0.07,0.09-0.1,0.13c-0.03,0.04-0.06,0.09-0.08,0.13s-0.05,0.09-0.08,0.15c-0.03,0.05-0.05,0.11-0.08,0.18
			c-0.03,0.07-0.06,0.14-0.08,0.21c-0.03,0.07-0.05,0.15-0.08,0.23s-0.05,0.16-0.07,0.24c-0.02,0.08-0.04,0.16-0.06,0.24
			c-0.04,0.16-0.06,0.32-0.08,0.47c-0.01,0.08-0.02,0.15-0.02,0.22c-0.01,0.07-0.01,0.14-0.01,0.22c-0.01,0.14-0.01,0.29-0.01,0.46
			c0,0.17,0,0.35,0,0.54c0,0.19,0.01,0.38,0.02,0.55c0.01,0.09,0.01,0.17,0.02,0.25c0.01,0.08,0.02,0.16,0.03,0.24
			c0.01,0.08,0.02,0.15,0.04,0.23c0.01,0.04,0.01,0.07,0.02,0.11c0.01,0.04,0.01,0.07,0.02,0.11c0.01,0.07,0.03,0.14,0.04,0.2
			c0.01,0.06,0.03,0.12,0.04,0.18c0.01,0.03,0.02,0.05,0.02,0.08s0.02,0.05,0.03,0.08c0.02,0.05,0.04,0.11,0.07,0.16
			s0.06,0.11,0.09,0.17c0.03,0.06,0.07,0.12,0.11,0.17c0.04,0.06,0.07,0.12,0.11,0.18s0.08,0.12,0.12,0.17
			c0.04,0.06,0.09,0.11,0.14,0.17c0.05,0.06,0.1,0.12,0.16,0.18c0.06,0.06,0.12,0.12,0.19,0.18c0.07,0.06,0.14,0.12,0.22,0.19
			c0.15,0.12,0.32,0.25,0.49,0.36c0.08,0.06,0.17,0.11,0.25,0.16c0.08,0.05,0.16,0.09,0.23,0.13c0.08,0.04,0.15,0.07,0.22,0.1
			c0.07,0.03,0.14,0.06,0.21,0.08c0.07,0.02,0.14,0.05,0.2,0.07s0.14,0.04,0.2,0.06c0.07,0.02,0.14,0.04,0.21,0.06
			s0.14,0.04,0.21,0.05c0.07,0.02,0.15,0.04,0.23,0.05c0.08,0.02,0.17,0.04,0.27,0.05c0.05,0.01,0.1,0.02,0.16,0.03
			s0.11,0.02,0.17,0.03c0.12,0.02,0.25,0.04,0.39,0.05c0.14,0.02,0.28,0.03,0.42,0.04c0.14,0.01,0.28,0.02,0.41,0.02'
			/>
			<line x1='68.87' y1='347.16' x2='74.96' y2='347.16' />
			<line x1='68.87' y1='357.47' x2='74.49' y2='357.47' />
			<line x1='70.83' y1='285.92' x2='91.86' y2='285.92' />
			<rect x='185.57' y='169.51' width='20.74' height='44.29' />
			<line x1='206.32' y1='169.51' x2='196.61' y2='183.41' />
			<line x1='194.93' y1='183.39' x2='185.57' y2='169.51' />
			<line x1='195.27' y1='186.01' x2='185.57' y2='213.8' />
			<line x1='206.32' y1='213.8' x2='196.26' y2='186.01' />
			<circle cx='195.76' cy='184.62' r='1.48' />
			<line x1='209.27' y1='214.23' x2='209.27' y2='202.77' />
			<line x1='209.27' y1='200.49' x2='209.27' y2='169.14' />
			<path d='M123.05,201.04c-1.2,4.07-1.2,8.39,0,12.46' />
			<circle cx='138.79' cy='229.29' r='0.19' />
			<line x1='139.45' y1='228.71' x2='138.13' y2='228.71' />
			<polyline points='136.92,227.09 136.92,218.51 125.67,218.51 125.67,236.51 136.92,236.51 136.92,227.93 		' />
			<path
				d='M133.12,227.22c-0.16-0.38-0.6-0.56-0.98-0.41s-0.56,0.6-0.41,0.98c0.16,0.38,0.6,0.56,0.98,0.41
			c0.18-0.08,0.33-0.22,0.41-0.41'
			/>
			<path d='M123.05,260.42c-1.2,4.07-1.2,8.39,0,12.46' />
			<path
				d='M125.67,259.37c0.11,0.22,0.38,0.31,0.6,0.2c0.22-0.11,0.31-0.38,0.2-0.6c-0.11-0.22-0.38-0.31-0.6-0.2
			c-0.09,0.04-0.16,0.11-0.2,0.2'
			/>
			<line x1='125.43' y1='258.96' x2='126.48' y2='258.96' />
			<line x1='125.43' y1='259.37' x2='126.48' y2='259.37' />
			<path
				d='M125.67,264.34c0.11,0.22,0.38,0.31,0.6,0.2c0.22-0.11,0.31-0.38,0.2-0.6c-0.11-0.22-0.38-0.31-0.6-0.2
			c-0.09,0.04-0.16,0.11-0.2,0.2'
			/>
			<line x1='125.43' y1='263.94' x2='126.48' y2='263.94' />
			<line x1='125.43' y1='264.34' x2='126.48' y2='264.34' />
			<path
				d='M125.67,274.34c0.11,0.22,0.38,0.31,0.6,0.2s0.31-0.38,0.2-0.6c-0.11-0.22-0.38-0.31-0.6-0.2
			c-0.09,0.04-0.16,0.11-0.2,0.2'
			/>
			<line x1='125.43' y1='274.34' x2='126.48' y2='274.34' />
			<line x1='125.43' y1='273.94' x2='126.48' y2='273.94' />
			<path
				d='M125.67,269.36c0.11,0.22,0.38,0.31,0.6,0.2s0.31-0.38,0.2-0.6c-0.11-0.22-0.38-0.31-0.6-0.2
			c-0.09,0.04-0.16,0.11-0.2,0.2'
			/>
			<line x1='125.43' y1='269.36' x2='126.48' y2='269.36' />
			<line x1='125.43' y1='268.96' x2='126.48' y2='268.96' />
			<line x1='127.79' y1='277.9' x2='127.79' y2='255.4' />
			<line x1='128.32' y1='277.9' x2='128.32' y2='272.58' />
			<line x1='128.32' y1='272.35' x2='128.32' y2='255.4' />
			<line x1='140' y1='277.9' x2='140' y2='272.58' />
			<line x1='140' y1='272.35' x2='140' y2='260.96' />
			<line x1='140' y1='260.72' x2='140' y2='255.4' />
			<line x1='128.89' y1='277.9' x2='128.89' y2='272.58' />
			<line x1='128.89' y1='272.35' x2='128.89' y2='260.96' />
			<line x1='128.89' y1='260.72' x2='128.89' y2='255.4' />
			<line x1='129.17' y1='277.9' x2='129.17' y2='272.58' />
			<line x1='129.17' y1='272.35' x2='129.17' y2='260.96' />
			<line x1='129.17' y1='260.72' x2='129.17' y2='255.4' />
			<line x1='138.87' y1='271.09' x2='138.87' y2='262.22' />
			<line x1='138.87' y1='259.46' x2='138.87' y2='255.4' />
			<line x1='138.87' y1='273.84' x2='138.87' y2='277.9' />
			<line x1='139.16' y1='271.09' x2='139.16' y2='262.21' />
			<line x1='139.16' y1='259.46' x2='139.16' y2='255.4' />
			<line x1='139.16' y1='273.84' x2='139.16' y2='277.9' />
			<line x1='137.44' y1='270.99' x2='137.44' y2='262.32' />
			<line x1='137.44' y1='259.36' x2='137.44' y2='255.4' />
			<line x1='137.44' y1='273.95' x2='137.44' y2='277.9' />
			<line x1='137.72' y1='270.99' x2='137.72' y2='262.32' />
			<line x1='137.72' y1='259.36' x2='137.72' y2='255.4' />
			<line x1='137.72' y1='273.95' x2='137.72' y2='277.9' />
			<line x1='136.29' y1='271.07' x2='136.29' y2='262.23' />
			<line x1='136.29' y1='259.45' x2='136.29' y2='255.4' />
			<line x1='136.29' y1='273.85' x2='136.29' y2='277.9' />
			<line x1='136.01' y1='271.08' x2='136.01' y2='262.22' />
			<line x1='136.01' y1='259.45' x2='136.01' y2='255.4' />
			<line x1='136.01' y1='273.85' x2='136.01' y2='277.9' />
			<line x1='133.13' y1='271.09' x2='133.13' y2='262.22' />
			<line x1='133.13' y1='259.46' x2='133.13' y2='255.4' />
			<line x1='133.13' y1='273.84' x2='133.13' y2='277.9' />
			<line x1='133.41' y1='271.09' x2='133.41' y2='262.21' />
			<line x1='133.41' y1='259.46' x2='133.41' y2='255.4' />
			<line x1='133.41' y1='273.84' x2='133.41' y2='277.9' />
			<line x1='131.7' y1='270.99' x2='131.7' y2='262.32' />
			<line x1='131.7' y1='259.36' x2='131.7' y2='255.4' />
			<line x1='131.7' y1='273.95' x2='131.7' y2='277.9' />
			<line x1='131.98' y1='270.99' x2='131.98' y2='262.32' />
			<line x1='131.98' y1='259.36' x2='131.98' y2='255.4' />
			<line x1='131.98' y1='273.95' x2='131.98' y2='277.9' />
			<line x1='130.55' y1='271.07' x2='130.55' y2='262.23' />
			<line x1='130.55' y1='259.45' x2='130.55' y2='255.4' />
			<line x1='130.55' y1='273.85' x2='130.55' y2='277.9' />
			<line x1='130.26' y1='271.08' x2='130.26' y2='262.22' />
			<line x1='130.26' y1='259.45' x2='130.26' y2='255.4' />
			<line x1='130.26' y1='273.85' x2='130.26' y2='277.9' />
			<line x1='134.79' y1='277.9' x2='134.79' y2='272.58' />
			<line x1='134.79' y1='272.35' x2='134.79' y2='260.96' />
			<line x1='134.79' y1='260.72' x2='134.79' y2='255.4' />
			<line x1='134.51' y1='277.9' x2='134.51' y2='272.58' />
			<line x1='134.51' y1='272.35' x2='134.51' y2='260.96' />
			<line x1='134.51' y1='260.72' x2='134.51' y2='255.4' />
			<line x1='127.79' y1='266.65' x2='140.53' y2='266.65' />
			<line x1='128.32' y1='267.08' x2='128.89' y2='267.08' />
			<line x1='129.17' y1='267.08' x2='130.26' y2='267.08' />
			<line x1='130.55' y1='267.08' x2='131.7' y2='267.08' />
			<line x1='131.98' y1='267.08' x2='133.13' y2='267.08' />
			<line x1='133.41' y1='267.08' x2='134.51' y2='267.08' />
			<line x1='134.79' y1='267.08' x2='136.01' y2='267.08' />
			<line x1='136.29' y1='267.08' x2='137.44' y2='267.08' />
			<line x1='137.72' y1='267.08' x2='138.87' y2='267.08' />
			<line x1='139.16' y1='267.08' x2='140' y2='267.08' />
			<line x1='128.32' y1='266.22' x2='128.89' y2='266.22' />
			<line x1='129.17' y1='266.22' x2='130.26' y2='266.22' />
			<line x1='130.55' y1='266.22' x2='131.7' y2='266.22' />
			<line x1='131.98' y1='266.22' x2='133.13' y2='266.22' />
			<line x1='133.41' y1='266.22' x2='134.51' y2='266.22' />
			<line x1='134.79' y1='266.22' x2='136.01' y2='266.22' />
			<line x1='136.29' y1='266.22' x2='137.44' y2='266.22' />
			<line x1='137.72' y1='266.22' x2='138.87' y2='266.22' />
			<line x1='139.16' y1='266.22' x2='140' y2='266.22' />
			<line x1='140' y1='277.47' x2='139.16' y2='277.47' />
			<line x1='138.87' y1='277.47' x2='137.72' y2='277.47' />
			<line x1='137.44' y1='277.47' x2='136.29' y2='277.47' />
			<line x1='136.01' y1='277.47' x2='134.79' y2='277.47' />
			<line x1='134.51' y1='277.47' x2='133.41' y2='277.47' />
			<line x1='133.13' y1='277.47' x2='131.98' y2='277.47' />
			<line x1='131.7' y1='277.47' x2='130.55' y2='277.47' />
			<line x1='130.26' y1='277.47' x2='129.17' y2='277.47' />
			<line x1='128.89' y1='277.47' x2='128.32' y2='277.47' />
			<line x1='128.32' y1='255.84' x2='128.89' y2='255.84' />
			<line x1='129.17' y1='255.84' x2='130.26' y2='255.84' />
			<line x1='130.55' y1='255.84' x2='131.7' y2='255.84' />
			<line x1='131.98' y1='255.84' x2='133.13' y2='255.84' />
			<line x1='133.41' y1='255.84' x2='134.51' y2='255.84' />
			<line x1='134.79' y1='255.84' x2='136.01' y2='255.84' />
			<line x1='136.29' y1='255.84' x2='137.44' y2='255.84' />
			<line x1='137.72' y1='255.84' x2='138.87' y2='255.84' />
			<line x1='139.16' y1='255.84' x2='140' y2='255.84' />
			<line x1='140' y1='260.96' x2='139.06' y2='260.96' />
			<line x1='136.1' y1='260.96' x2='134.79' y2='260.96' />
			<line x1='134.51' y1='260.96' x2='133.32' y2='260.96' />
			<line x1='130.36' y1='260.96' x2='129.17' y2='260.96' />
			<line x1='128.89' y1='260.96' x2='128.32' y2='260.96' />
			<line x1='140' y1='260.72' x2='139.06' y2='260.72' />
			<line x1='136.1' y1='260.72' x2='134.79' y2='260.72' />
			<line x1='134.51' y1='260.72' x2='133.32' y2='260.72' />
			<line x1='130.36' y1='260.72' x2='129.17' y2='260.72' />
			<line x1='128.89' y1='260.72' x2='128.32' y2='260.72' />
			<line x1='130.36' y1='272.35' x2='129.17' y2='272.35' />
			<line x1='128.89' y1='272.35' x2='128.32' y2='272.35' />
			<line x1='133.32' y1='272.35' x2='134.51' y2='272.35' />
			<line x1='134.79' y1='272.35' x2='136.1' y2='272.35' />
			<line x1='139.06' y1='272.35' x2='140' y2='272.35' />
			<line x1='130.36' y1='272.58' x2='129.17' y2='272.58' />
			<line x1='128.89' y1='272.58' x2='128.32' y2='272.58' />
			<line x1='133.32' y1='272.58' x2='134.51' y2='272.58' />
			<line x1='134.79' y1='272.58' x2='136.1' y2='272.58' />
			<line x1='139.06' y1='272.58' x2='140' y2='272.58' />
			<circle cx='137.58' cy='260.84' r='0.86' />
			<path d='M138.52,261.6c0.36-0.44,0.36-1.08,0-1.52' />
			<path d='M138.38,259.93c-0.45-0.4-1.13-0.4-1.59-0.01' />
			<path d='M136.65,260.07c-0.36,0.45-0.36,1.09,0,1.53' />
			<path d='M136.79,261.75c0.46,0.4,1.14,0.39,1.59-0.01' />
			<circle cx='137.58' cy='272.46' r='0.75' />
			<path d='M136.83,273.34c0.44,0.38,1.08,0.37,1.52-0.01' />
			<path d='M138.48,273.19c0.34-0.42,0.34-1.02,0-1.45' />
			<path d='M138.34,271.59c-0.43-0.38-1.08-0.38-1.52-0.01' />
			<path d='M136.68,271.73c-0.35,0.43-0.35,1.03,0,1.46' />
			<path d='M130.76,271.56c-0.44,0.52-0.44,1.29,0,1.81' />
			<path d='M130.9,273.52c0.54,0.48,1.35,0.48,1.88-0.01' />
			<path d='M132.92,273.37c0.43-0.52,0.43-1.28,0-1.8' />
			<path d='M132.78,271.42c-0.53-0.48-1.35-0.48-1.88-0.01' />
			<path d='M130.04,272.58c0.03,0.39,0.18,0.76,0.44,1.06' />
			<path d='M130.61,273.79c0.3,0.27,0.68,0.44,1.08,0.47' />
			<path d='M131.98,274.26c0.41-0.03,0.79-0.2,1.09-0.48' />
			<path d='M133.21,273.64c0.25-0.3,0.41-0.67,0.43-1.06' />
			<path d='M133.64,272.35c-0.03-0.39-0.18-0.76-0.43-1.06' />
			<path d='M133.07,271.14c-0.3-0.28-0.68-0.45-1.09-0.48' />
			<path d='M131.7,270.67c-0.4,0.03-0.78,0.2-1.08,0.47' />
			<path d='M130.47,271.28c-0.26,0.3-0.41,0.67-0.44,1.06' />
			<path d='M133.36,260.72c-0.02-0.32-0.15-0.62-0.35-0.86' />
			<path d='M132.86,259.71c-0.24-0.22-0.55-0.36-0.88-0.39' />
			<path d='M131.7,259.32c-0.33,0.03-0.63,0.17-0.88,0.39' />
			<path d='M130.68,259.85c-0.21,0.24-0.33,0.55-0.36,0.87' />
			<path d='M130.32,260.96c0.03,0.32,0.15,0.62,0.36,0.87' />
			<path d='M130.82,261.97c0.24,0.22,0.55,0.36,0.88,0.39' />
			<path d='M131.98,262.35c0.33-0.03,0.64-0.17,0.88-0.39' />
			<path d='M133.01,261.82c0.2-0.24,0.33-0.54,0.35-0.86' />
			<path d='M133.68,260.72c-0.03-0.4-0.18-0.78-0.44-1.09' />
			<path d='M133.1,259.48c-0.31-0.29-0.7-0.46-1.12-0.49' />
			<path d='M131.7,258.99c-0.41,0.03-0.81,0.2-1.11,0.49' />
			<path d='M130.44,259.63c-0.26,0.31-0.42,0.69-0.45,1.09' />
			<path d='M129.99,260.96c0.03,0.4,0.18,0.79,0.45,1.09' />
			<path d='M130.58,262.19c0.31,0.28,0.7,0.45,1.11,0.49' />
			<path d='M131.98,262.68c0.42-0.03,0.81-0.21,1.12-0.49' />
			<path d='M133.24,262.04c0.26-0.3,0.42-0.69,0.44-1.09' />
			<line x1='131.02' y1='273.12' x2='130.26' y2='273.85' />
			<line x1='132.52' y1='271.67' x2='133.13' y2='271.09' />
			<line x1='131.16' y1='273.27' x2='130.55' y2='273.85' />
			<line x1='132.66' y1='271.82' x2='133.41' y2='271.09' />
			<line x1='131.02' y1='271.81' x2='130.26' y2='271.08' />
			<line x1='132.52' y1='273.26' x2='133.13' y2='273.84' />
			<line x1='131.16' y1='271.66' x2='130.55' y2='271.07' />
			<line x1='132.66' y1='273.11' x2='133.41' y2='273.84' />
			<line x1='131.98' y1='270.99' x2='131.7' y2='270.99' />
			<line x1='131.98' y1='273.95' x2='131.7' y2='273.95' />
			<line x1='132.52' y1='271.67' x2='132.66' y2='271.82' />
			<line x1='132.52' y1='273.26' x2='132.66' y2='273.11' />
			<line x1='131.16' y1='271.66' x2='131.02' y2='271.81' />
			<line x1='131.16' y1='273.27' x2='131.02' y2='273.12' />
			<line x1='133.32' y1='272.35' x2='133.32' y2='272.58' />
			<line x1='130.36' y1='272.35' x2='130.36' y2='272.58' />
			<line x1='136.76' y1='273.12' x2='136.01' y2='273.85' />
			<line x1='138.26' y1='271.67' x2='138.87' y2='271.09' />
			<line x1='136.9' y1='273.27' x2='136.29' y2='273.85' />
			<line x1='138.41' y1='271.82' x2='139.16' y2='271.09' />
			<line x1='136.76' y1='271.81' x2='136.01' y2='271.08' />
			<line x1='138.26' y1='273.26' x2='138.87' y2='273.84' />
			<line x1='136.9' y1='271.66' x2='136.29' y2='271.07' />
			<line x1='138.41' y1='273.11' x2='139.16' y2='273.84' />
			<line x1='137.72' y1='270.99' x2='137.44' y2='270.99' />
			<line x1='137.72' y1='273.95' x2='137.44' y2='273.95' />
			<line x1='138.26' y1='271.67' x2='138.41' y2='271.82' />
			<line x1='138.26' y1='273.26' x2='138.41' y2='273.11' />
			<line x1='136.9' y1='271.66' x2='136.76' y2='271.81' />
			<line x1='136.9' y1='273.27' x2='136.76' y2='273.12' />
			<line x1='139.06' y1='272.35' x2='139.06' y2='272.58' />
			<line x1='136.1' y1='272.35' x2='136.1' y2='272.58' />
			<line x1='136.76' y1='261.49' x2='136.01' y2='262.22' />
			<line x1='138.26' y1='260.04' x2='138.87' y2='259.46' />
			<line x1='136.9' y1='261.64' x2='136.29' y2='262.23' />
			<line x1='138.41' y1='260.19' x2='139.16' y2='259.46' />
			<line x1='136.76' y1='260.18' x2='136.01' y2='259.45' />
			<line x1='138.26' y1='261.63' x2='138.87' y2='262.22' />
			<line x1='136.9' y1='260.04' x2='136.29' y2='259.45' />
			<line x1='138.41' y1='261.49' x2='139.16' y2='262.21' />
			<line x1='137.72' y1='259.36' x2='137.44' y2='259.36' />
			<line x1='137.72' y1='262.32' x2='137.44' y2='262.32' />
			<line x1='138.26' y1='260.04' x2='138.41' y2='260.19' />
			<line x1='138.26' y1='261.63' x2='138.41' y2='261.49' />
			<line x1='136.9' y1='260.04' x2='136.76' y2='260.18' />
			<line x1='136.9' y1='261.64' x2='136.76' y2='261.49' />
			<line x1='139.06' y1='260.72' x2='139.06' y2='260.96' />
			<line x1='136.1' y1='260.72' x2='136.1' y2='260.96' />
			<line x1='131.02' y1='261.49' x2='130.26' y2='262.22' />
			<line x1='132.52' y1='260.04' x2='133.13' y2='259.46' />
			<line x1='131.16' y1='261.64' x2='130.55' y2='262.23' />
			<line x1='132.66' y1='260.19' x2='133.41' y2='259.46' />
			<line x1='131.02' y1='260.18' x2='130.26' y2='259.45' />
			<line x1='132.52' y1='261.63' x2='133.13' y2='262.22' />
			<line x1='131.16' y1='260.04' x2='130.55' y2='259.45' />
			<line x1='132.66' y1='261.49' x2='133.41' y2='262.21' />
			<line x1='131.98' y1='259.36' x2='131.7' y2='259.36' />
			<line x1='131.98' y1='262.32' x2='131.7' y2='262.32' />
			<line x1='132.52' y1='260.04' x2='132.66' y2='260.19' />
			<line x1='132.52' y1='261.63' x2='132.66' y2='261.49' />
			<line x1='131.16' y1='260.04' x2='131.02' y2='260.18' />
			<line x1='131.16' y1='261.64' x2='131.02' y2='261.49' />
			<line x1='133.32' y1='260.72' x2='133.32' y2='260.96' />
			<line x1='130.36' y1='260.72' x2='130.36' y2='260.96' />
		</g>
	);
};

export default ThinLineworks;
