import {utils} from 'photo-sphere-viewer';
/*************************************
 * If it is development mode
 * log latitude and longitude
 ************************************/
export const checkLatLong = (data: any, log: boolean = true) => {
	// if (process.env.NODE_ENV === "development") {
	//   log &&
	// console.log(`latitude: ${data.latitude}, longitude: ${data.longitude},`)
	// }
};

/*************************************
 * Calculate spherical viewer angle
 ************************************/
export const getAngle = (longitude: number): string => {
	const angle = utils.parseAngle(longitude, true, false);
	const degree = angle * (180 / Math.PI);
	const output = degree.toString() + 'deg';
	return output;
};

export const getViewAngle = (longitude: number): string => {
	const angle = utils.parseAngle(longitude, true, false);
	let degree = angle * (180 / Math.PI);
	// degree += 180;
	const output = degree.toString() + 'deg';
	return output;
};
